<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div class="mt-4">
    <b> {{ $vuetify.lang.t('$vuetify.callback.loading_text') }} </b>
  </div>
</template>
<script>
import { getAccessToken } from '../auth/auth'

export default {
  data() {
    return {}
  },
  mounted() {
    // Save the access token
    getAccessToken(this.$route.query.code)
  },
}
</script>
